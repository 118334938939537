import hljs from "highlight.js";
import "highlight.js/styles/atom-one-dark.css";

hljs.configure({
    languages: ["haskell", "bash", "css", "javascript"]
});

export const highlightCode = () => {
    const codes = document.querySelectorAll("pre > code")
    for (let key in codes) {
        if (typeof codes[key] == "object") {
            hljs.highlightElement(codes[key]);
        }
    }
}

const obj = { highlightCode };

export default obj;
