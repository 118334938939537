import React, {useEffect} from "react";
import {graphql} from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/layout";
import "../styles/blog-post-template.css";
import helper from "../utils/helper";
import Navbar from "../components/navbar";

const BlogPost = ({data}) => {
    const post = data.markdownRemark;

    useEffect(() => {
        helper.highlightCode();
    })

    return (
        <Layout>
            <Navbar />
            <div className="blog-post-template-container">
                <Seo title={post.frontmatter.title} description={post.frontmatter.desc}/>
                <h1 className="blog-post-template-title">{post.frontmatter.title}</h1>
                <small className="blog-post-template-date">posted {post.frontmatter.date}</small>
                <div className="blog-post-template-content" dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
        </Layout>
    )
}

export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        desc
        date(fromNow: true)
      }
    }
  }
`;

export default BlogPost;
